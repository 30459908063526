import React from 'react';
import {
  Dropdown,
  Button,
  Icon,
  DropdownMenu,
  DropdownContent,
  DropdownItemLink,
  ArrowDown,
} from '../../StyleComponents/styles';
const LangDropDown = () => {
  return (
    <Dropdown className="mb-4">
      <Button aria-haspopup="true" aria-controls="dropdown-menu">
        Keyboard Language
        <Icon>
        <ArrowDown />
        </Icon>
      </Button>
      <DropdownMenu id="dropdown-menu" role="menu">
        <DropdownContent>
          <DropdownItemLink to="/arabic-keyboard/" activeClassName="activeL">
            Arabic Keyboard
          </DropdownItemLink>
          <DropdownItemLink to="/multi-language-keyboard/" activeClassName="activeL">
            English Keyboard
          </DropdownItemLink>

          <DropdownItemLink to="/french-keyboard/" activeClassName="activeL">
            French Keyboard
          </DropdownItemLink>
          <DropdownItemLink to="/georgian-keyboard/" activeClassName="activeL">
            Georgian Keyboard
          </DropdownItemLink>
          <DropdownItemLink to="/russian-keyboard/" activeClassName="activeL">
            Russian Keyboard
          </DropdownItemLink>
          <DropdownItemLink to="/turkish-keyboard/" activeClassName="activeL">
            Turkish Keyboard
          </DropdownItemLink>
          <DropdownItemLink to="/portuguese-keyboard/" activeClassName="activeL">
            Portuguese Keyboard
          </DropdownItemLink>
        </DropdownContent>
      </DropdownMenu>
    </Dropdown>
  );
};
export default LangDropDown;
