import React, { useRef, useState } from "react"
import { FlexDiv, InputButton } from "../../../StyleComponents/pagesStyle"
import { Button, Textarea } from "../../../StyleComponents/styles"

function Keyboard({ languagesLetter, upperlanguagesLetter, direction }) {
  let textareaValue = useRef("")
  let [lang, setLang] = useState(languagesLetter)
  let [upper, setUppertrue] = useState(false)

  function addLetters(letter) {
    textareaValue.current.focus()
    textareaValue.current.value += letter
  }

  // function removeLetter() {
  //   textareaValue.current.focus()
  //   textareaValue.current.value = textareaValue.current.value.substring(
  //     0,
  //     textareaValue.current.value.length - 1
  //   )
  // }

  function toUpperCase() {
    if (lang === languagesLetter) {
      setUppertrue(true)
      setLang(upperlanguagesLetter)
    } else {
      setUppertrue(false)
      setLang(languagesLetter)
    }
  }

  function addLetterToTextarea(e) {
    addLetters(e.target.value)
  }

  return (
    <>
      <FlexDiv
        justifyContent="center"
        displayDiv="inline-flex"
        className="mb-4"
      >
        <Button
          onClick={e => {
            let targetValue = e.target
            textareaValue.current.select()
            document.execCommand("copy")
            targetValue.textContent = "Copied"
            setTimeout(() => {
              targetValue.textContent = "Copy"
            }, 1500)
          }}
        >
          Copy
        </Button>

        <Button
          onClick={() => {
            textareaValue.current.focus()
            textareaValue.current.value = ""
          }}
        >
          Clear all
        </Button>

        {upperlanguagesLetter ? (
          <Button onClick={toUpperCase}>
            Uppercase{" "}
            <span style={upper ? { color: "red" } : { color: "black" }}>
              &#11047;
            </span>
          </Button>
        ) : (
          ""
        )}
      </FlexDiv>

      <Textarea
        ref={textareaValue}
        data-gramm="true"
        spellCheck="false"
        data-gramm_editor="true"
        dir={direction ? direction : ""}
      ></Textarea>

      <br />
      <FlexDiv justifyContent="center" displayDiv="inline-flex" flexWrap="wrap">
        {lang.letter.map((e, i) => {
          return (
            <InputButton
              type="button"
              key={i}
              value={e}
              onClick={addLetterToTextarea}
            />
          )
        })}
        {/* <InputButton type="button" onClick={removeLetter} 
             value="BACK SPACE"
            /> */}
      </FlexDiv>
      <FlexDiv justifyContent="center" displayDiv="inline-flex" flexWrap="wrap">
        {lang.letter2.map((e, i) => {
          return (
            <InputButton
              type="button"
              key={i}
              value={e}
              onClick={addLetterToTextarea}
            />
          )
        })}
        <InputButton
          height="70px"
          width="60px"
          type="button"
          name="letter_jo"
          value="Enter"
          onClick={() => {
            textareaValue.current.focus()
            textareaValue.current.value += "\n"
          }}
        />
      </FlexDiv>

      <FlexDiv justifyContent="center" displayDiv="inline-flex" flexWrap="wrap">
        {lang.letter3.map((e, i) => {
          return (
            <InputButton
              type="button"
              key={i}
              value={e}
              onClick={addLetterToTextarea}
            />
          )
        })}
      </FlexDiv>

      <FlexDiv justifyContent="center" displayDiv="inline-flex" flexWrap="wrap">
        {lang.letter4.map((e, i) => {
          return (
            <InputButton
              type="button"
              key={i}
              value={e}
              onClick={addLetterToTextarea}
            />
          )
        })}
      </FlexDiv>

      <FlexDiv justifyContent="center" displayDiv="inline-flex" flexWrap="wrap">
        <InputButton
          width="30%"
          type="button"
          name="spacja"
          value=" "
          onClick={addLetterToTextarea}
        />
      </FlexDiv>
      <br />

      {lang.axtraLetterIsTrue ? (
        <div>
          <p>Extra characters</p>
          <FlexDiv
            justifyContent="center"
            displayDiv="inline-flex"
            flexWrap="wrap"
          >
            {lang.extraLetter.map(function (e, index) {
              return (
                <InputButton
                  type="button"
                  key={index}
                  value={e}
                  onClick={addLetterToTextarea}
                />
              )
            })}
            <br />
          </FlexDiv>
        </div>
      ) : (
        ""
      )}
      <br />
    </>
  )
}

export default Keyboard
