import React, { useMemo } from "react"

import Layout from "../../components/Layout"
import SEO from "../../components/SEO/SEO"
import NavBar from "../../components/NavBar/NavBar"
import SeeAlso from "../../components/SeeAlso/SeeAlso"
import Keyboard from "./components/Keywords"
import LangDropDown from "../../componentForPages/KeyboardPages/languageKeyboard"
import { Title } from "../../StyleComponents/styles"

const LangKeyboards = props => {
  const { node } = props.pageContext
  return (
    <Layout location={props.location}>
      <SEO
        title={node.title}
        description={node.description}
        keywords={[node.keywords]}
      />
      <NavBar
        listPages={useMemo(() => {
          return [
            { name: "Utility Tools", link: "/utility-tools/" },
            { name: `${node.title}`, link: `/${node.path}/` },
          ]
        }, [node.title, node.path])}
      />
      <div className="container">
        <Title>{node.title}</Title>
        <LangDropDown />

        <Keyboard
          languagesLetter={node.languagesLetter}
          upperlanguagesLetter={node.upperlanguagesLetter}
          direction={node.direction}
        />
        <br />
        <SeeAlso seeAlsoList={node.SeeAlso} />
        <br />
      </div>
    </Layout>
  )
}
export default LangKeyboards
